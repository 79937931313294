import { approvalStatuses } from "data/constants";
import { useApplications } from "hooks/applications/useApplication";
import { ApplicationFilter } from "models/applications";
import { ApprovalStatus } from "models/commons";
import { PagingAndSortingFilter, SortDirection } from "models/filters";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { deleteApplication } from "services/application-service";
import FilterBar, { Filter } from "shared/Filter/FilterBar";
import PillMultiSelectFilter, { FilterOption } from "shared/Filter/PillMultiSelectFilter";
import ListResultsInformation from "shared/ListResultsInformation/ListResultsInformation";
import Pagination from "shared/Pagination/Pagination";
import { getApprovalStatus } from "utils/getApprovalStatus";
import { getBasicFilterFromSearchParams } from "utils/queryParamFilterUtils";
import MyApplicationCard from "./MyApplicationCard";


interface ApplicationSelectedFilterOptions {
    status: FilterOption<ApprovalStatus>[];
}


const MyApplicationsPage = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<PagingAndSortingFilter>({} as PagingAndSortingFilter);
    const [currentPage, setCurrentPage] = useState(0);
    const { data, error, isLoading } = useApplications(filter, [filter]);
    const [keywordAndSortingFilter, setKeywordAndSortingFilter] = useState<Filter>({} as Filter);
    const [selectedOptions, setSelectedOptions] = useState<ApplicationSelectedFilterOptions>({ status: [] });

    const statusOptions = approvalStatuses.map((status) => {
        return { label: getApprovalStatus(t, status), value: status } as FilterOption<ApprovalStatus>;
    });

    const sortOptions = [
        { label: t("common.dateCreated", "Date Created"), value: "dateCreated" },
        { label: t("common.lastModified", "Last Modified"), value: "lastModified" },
        { label: t("listing.title", "Title"), value: "listing.title" },
    ];
    useEffect(() => {
        if (data) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [data]);

    useEffect(() => {
        const newFilter = getBasicFilterFromSearchParams(searchParams, "lastModified") as ApplicationFilter;
        newFilter.landlordStatus = searchParams.getAll("status") as ApprovalStatus[];
        setFilter(newFilter);
        if (newFilter.page) {
            setCurrentPage(newFilter.page);
        }
        setKeywordAndSortingFilter({
            keyword: newFilter.keyword || "",
            sortField: newFilter.sortField,
            direction: newFilter.direction
        });
        setSelectedOptions({
            status: newFilter.landlordStatus ? statusOptions.filter((option) => newFilter.landlordStatus?.includes(option.value)) : []
        });
    }, [searchParams]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleApplicationDeleted = (id: string) => {
        deleteApplication(id).then(() => {
            handlePageChange(currentPage);
        }).catch((error) => {
            console.error(error);
        });
    }

    const handleLandlordStatusChanged = (selectedOptions: FilterOption<ApprovalStatus>[]) => {
        const newFilter = { ...filter, landlordStatus: selectedOptions.map((option) => option.value) };
        setFilter(newFilter);
        searchParams.delete("status");
        if (selectedOptions.length > 0) {
            selectedOptions.forEach(option => searchParams.append("status", option.value));
        }
        else {
            searchParams.set("status", "");
        }
        setSearchParams(searchParams);
    }

    const handleKeywordChange = (keyword: string) => {
        const newFilter = { ...filter, keyword: keyword };
        setFilter(newFilter);
        searchParams.set("keyword", keyword);
        setSearchParams(searchParams);
    }

    const handleSortChange = (sortField: string) => {
        const newFilter = { ...filter, sort: sortField };
        setFilter(newFilter);
        searchParams.set("sortField", sortField);
        setSearchParams(searchParams);
    }

    const handleDirectionChange = (direction: SortDirection) => {
        const newFilter = { ...filter, direction: direction };
        setFilter(newFilter);
        searchParams.set("sortDirection", direction);
        setSearchParams(searchParams);
    }

    const renderSection1 = () => {

        return (
            <div className="space-y-6 sm:space-y-8">
                <div>
                    <h2 className="text-3xl font-semibold">{t("page.myApplications.title", "My Appplications")}</h2>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="group relative  bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl p-6">

                    <div className="space-y-8">
                        <FilterBar
                            filter={keywordAndSortingFilter}
                            sortOptions={sortOptions}
                            onDirectionChange={handleDirectionChange}
                            onKeywordChange={handleKeywordChange}
                            onSortChange={handleSortChange}
                        />
                        <div className="flex space-x-4">
                            <PillMultiSelectFilter
                                label={t("page.applicationReview.status", "Status")}
                                defaultSelection={selectedOptions.status}
                                options={statusOptions}
                                onSelectionChange={handleLandlordStatusChanged} />
                        </div>
                        <ListResultsInformation label={t("page.myApplications.results.description", "Number of applications: ")} totalResults={data?.totalSize} isLoading={isLoading} error={error} className="w-full" />
                    </div>
                </div>
                <div>
                    <div className="grid grid-cols-1 gap-6 md:gap-8">
                        {data?.payload.map((item) => (
                            <MyApplicationCard key={item.externalId} handleApplicationDeleted={() => handleApplicationDeleted(item.externalId)} application={item} href={`/listing/${item.listing.externalId}`} />
                        ))}
                    </div>
                    {data && data.totalPages > 1 && (
                        <div className="flex mt-16 justify-center items-center">
                            <Pagination
                                totalPages={data.totalPages}
                                currentPage={currentPage}
                                setCurrentPage={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 ">

            <div className="container pt-6 pb-24 lg:pb-32">
                {renderSection1()}
            </div>
        </div>

    );
};

export default MyApplicationsPage;
