import { FC, FormEvent, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "services/user-service";
import { Alert } from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { getException } from "utils/getException";
export interface FinishRegistrationPageProps {
  className?: string;
}

type ResetPasswordFromData = {
  email?: string;
  password?: string;
  repeatPassword?: string;
};

const ResetPasswordPage: FC<FinishRegistrationPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get('token');
  const email = searchParams.get('email');
  const [errors, setErrors] = useState<{ email?: string; password?: string, repeatPassword?: string }>({});
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>(null);
  const [formData, setFormData] = useState({ email: email } as ResetPasswordFromData);

  function validateForm(): boolean {
    const newErrors: { email?: string; password?: string; repeatPassword?: string } = {};
    if (!formData.email) {
      newErrors.email = t("common.validation.email", "Email is required");
    }
    if (!formData.password) {
      newErrors.password = t("common.validation.password.required", "Password is required");
    }
    else if (formData.password.length < 8) {
      newErrors.password = t("common.validation.password", "Password must be at least 8 characters");
    }
    if (!formData.repeatPassword) {
      newErrors.repeatPassword = "Repeat password is required";
    }
    else if (formData.password !== formData.repeatPassword) {
      newErrors.repeatPassword = t("common.validation.password.match", "Passwords do not match");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (validateForm()) {
      setApiError(null);

      // Call the login function from the user-service.ts file
      resetPassword(formData.email as string, resetPasswordToken as string, formData.password as string).then(() => {
        navigate("/login");
      }).catch((err) => {
        setApiError(getException(t, err.response.data.error.message));
      });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  if (!resetPasswordToken) {
    return (
      <div className="container flex flex-col items-center justify-center h-screen">
        <h2 className="text-3xl font-semibold text-neutral-900 dark:text-neutral-100">{t("page.finishRegistration.invalidToken", "Invalid token")}</h2>
        <Link to="/login" className="mt-4 text-primary-600 hover:underline">{t("common.button.login", "Log In")}</Link>
      </div>
    );
  }

  return (
    <div className={`nc-FinishRegistrationPage ${className}`} data-nc-id="FinishRegistrationPage">
      <Helmet>
        <title>{t("page.resetPassword.helmet", "Reset Password")}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          <title>{t("page.resetPassword.title", "Reset Password")}</title>
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t("common.field.email", "Email address")}
              </span>
              <Input
                disabled={true}
                type="email"
                name="email"
                value={formData.email}
                className="mt-1"
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t("common.field.password", "Password")}
              </span>
              <Input type="password" className="mt-1" name="password"
                value={formData.password}
                onChange={handleChange} />
              {errors.password && <p className="text-red-500">{errors.password}</p>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t("common.field.confirmPassword", "Repeat Password")}
              </span>
              <Input type="password" className="mt-1" name="repeatPassword"
                value={formData.repeatPassword}
                onChange={handleChange} />
              {errors.repeatPassword && <p className="text-red-500">{errors.repeatPassword}</p>}
            </label>

            <ButtonPrimary type="submit">{t("common.button.continue", "Continue")}</ButtonPrimary>
          </form>
          {/* ERROR */}
          {apiError &&
            <Alert type="error">
              {apiError}
            </Alert>
          }
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;