import { useGetData } from "hooks/useData";
import { ResponseObject } from "models/responses";
import { GeneralStats } from "models/stats";

export const useGeneralStats = (deps?: any[]) => {
    const endpoint = `/api/v1/stats/general`;

    const { data, error, isLoading } = useGetData<ResponseObject<GeneralStats>>(endpoint, {}, deps);

    return { data, error, isLoading };
};