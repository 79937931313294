import FormItem from "containers/Listing/ListingManage/FormItem";
import { SortDirection } from "models/filters";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";

export interface SortingOptions {
    value: string;
    label: string;
}

export interface Filter {
    keyword?: string;
    sortField: string;
    direction: SortDirection;
}

interface FilterBarProps {
    filter: Filter;
    sortOptions: SortingOptions[];
    onKeywordChange: (keyword: string) => void;
    onSortChange: (sortField: string) => void;
    onDirectionChange: (direction: SortDirection) => void;
}

const FilterBar: React.FC<FilterBarProps> = ({
    filter,
    sortOptions,
    onKeywordChange,
    onSortChange,
    onDirectionChange,
}) => {
    const { t } = useTranslation();
    const [internalFilter, setInternalFilter] = useState<Filter>(filter);

    useEffect(() => {
        setInternalFilter(filter);
    }, [filter]);

    const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilter({ ...internalFilter, keyword: e.target.value });
        onKeywordChange(e.target.value);
    };

    const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setInternalFilter({ ...internalFilter, sortField: e.target.value });
        onSortChange(e.target.value);
    };

    const handleDirectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setInternalFilter({ ...internalFilter, direction: e.target.value as SortDirection });
        onDirectionChange(e.target.value as SortDirection);
    };

    return (
        <div className="flex flex-col md:flex-row md:space-x-4">
            {/* ITEM */}
            <FormItem className="w-full" label={t("components.filterBar.search", "Search")}>
                <Input
                    onChange={handleKeywordChange}
                    value={internalFilter.keyword}
                    name="keyword"
                />
            </FormItem>

            <FormItem  className="w-full md:w-1/3"
                label={t("components.filterBar.orderBy", "Order by")}>
                <Select
                    name="sortField"
                    onChange={handleSortChange}
                    value={internalFilter.sortField}
                >
                    {sortOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
            </FormItem>

            <FormItem className="w-full md:w-1/3" label={t("components.filterBar.sortDirection", "Direction")}>
                <Select
                    name="direction"
                    onChange={handleDirectionChange}
                    value={internalFilter.direction}
                >
                    <option value={SortDirection.ASC}>{t("filter.direction.asc", "Ascending")}</option>
                    <option value={SortDirection.DESC}>{t("filter.direction.desc", "Descending")}</option>
                </Select>
            </FormItem>
        </div>
    );
};

export default FilterBar;