import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { PropertySize, PropertySizeValidationError } from "./PageManageListing";
import { UnitType } from "models/listings";

export interface ManageListingSizeProps {
  formData: PropertySize;
  onNextPage?: (data: PropertySize) => void;
  onBackPage?: (data: PropertySize) => void;
  index: string;
  maxIndex: string;
  validationErrors: PropertySizeValidationError;
  propertyType: UnitType;
}

const ManageListingSize: FC<ManageListingSizeProps> = ({ onBackPage, onNextPage, formData, index, maxIndex, validationErrors, propertyType }) => {
  const { t } = useTranslation();
  const [internalFormData, setInternalFormData] = useState<PropertySize>(formData);

  const handleOnNextPage = () => {
    if (onNextPage)
      onNextPage(internalFormData);
  }

  const handleOnBackPage = () => {
    if (onBackPage)
      onBackPage(internalFormData);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (propertyType !== UnitType.ROOM && e.target.name === 'areaSqm') {
      setInternalFormData({
        ...internalFormData,
        areaSqm: Number(e.target.value),
        totalAreaSqm: Number(e.target.value)
      });
    } else {
      setInternalFormData({
        ...internalFormData,
        [e.target.name]: e.target.value,
      });
    }
  }

  function handleBedroomsChange(value: number) {
    setInternalFormData({
      ...internalFormData,
      bedrooms: value
    });
  }

  function handleBathroomsChange(value: number) {
    setInternalFormData({
      ...internalFormData,
      bathrooms: value
    });
  }

  function handleKitchensChange(value: number) {
    setInternalFormData({
      ...internalFormData,
      kitchens: value
    });
  }

  return (
    <CommonLayout
      index={index}
      maxIndex={maxIndex}
      onNextPage={handleOnNextPage}
      onBackPage={handleOnBackPage}
    >
      <>
        <h2 className="text-2xl font-semibold">{t("page.manageListings.size.title", "Size of your listing")}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <FormItem label={t("page.manageListings.size.field.area.label", "Area")} desc={t("page.manageListings.size.field.area.description", "Area in square meters")}>
            <Input type="number" name="areaSqm" value={internalFormData.areaSqm} onChange={handleChange} />
            {validationErrors.areaSqm && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.areaSqm}</span>}
          </FormItem>
          {propertyType === UnitType.ROOM && (
            <FormItem label={t("page.manageListings.size.field.totalArea.label", "Total Area")} desc={t("page.manageListings.size.field.totalArea.description", "Total Area in square meters")}>
              <Input type="number" name="totalAreaSqm" value={internalFormData.totalAreaSqm} onChange={handleChange} />
              {validationErrors.totalAreaSqm && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.totalAreaSqm}</span>}
            </FormItem>
          )}
          <FormItem desc={t("page.manageListings.size.field.bedroom.description", "Number of bedrooms")}>
            <NcInputNumber label={t("page.manageListings.size.field.bedroom.label", "Bedrooms")} defaultValue={internalFormData.bedrooms} onChange={handleBedroomsChange} />
            {validationErrors.bedrooms && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.bedrooms}</span>}
          </FormItem>
          <FormItem desc={t("page.manageListings.size.field.bathroom.description", "Number of bathrooms")}>
            <NcInputNumber label={t("page.manageListings.size.field.bathroom.label", "Bathrooms")} defaultValue={internalFormData.bathrooms} onChange={handleBathroomsChange} />
            {validationErrors.bathrooms && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.bathrooms}</span>}
          </FormItem>
          <FormItem desc={t("page.manageListings.size.field.kitchen.description", "Number of kitchens")}>
            <NcInputNumber label={t("page.manageListings.size.field.kitchen.label", "kitchens")} defaultValue={internalFormData.kitchens} onChange={handleKitchensChange} />
            {validationErrors.kitchens && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.kitchens}</span>}
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default ManageListingSize;
