import { FC, FormEvent, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "services/user-service";
import { Alert } from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { getException } from "utils/getException";
export interface FinishRegistrationPageProps {
  className?: string;
}

type ForgotPasswordFormData = {
  email?: string;
};

const FinishRegistrationPage: FC<FinishRegistrationPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<{ email?: string }>({});
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>(null);
  const [formData, setFormData] = useState({ email: "" } as ForgotPasswordFormData);

  function validateForm(): boolean {
    const newErrors: { email?: string } = {};
    if (!formData.email) {
      newErrors.email = t("common.validation.email", "Email is required");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (validateForm()) {
      setApiError(null);

      // Call the login function from the user-service.ts file
      forgotPassword(formData.email as string).then(() => {
        navigate("/forgot-password-success");
      }).catch((err) => {
        setApiError(getException(t, err.response.data.error.message));
      });
    }
  }

  return (
    <div className={`nc-FinishRegistrationPage ${className}`} data-nc-id="FinishRegistrationPage">
      <Helmet>
        <title>{t("page.forgotPassword.title", "Forgot Password")}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          <title>{t("page.forgotPassword.title", "Forgot Password")}</title>
        </h2>
        {/* subtitle */}
        <p className="text-lg text-neutral-500 dark:text-neutral-400 text-center mb-10">
          {t("page.forgotPassword.description", "Are you sure you want to reset your password?")}
        </p>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t("common.field.email", "Email address")}
              </span>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1"
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </label>
            <ButtonPrimary type="submit">{t("common.button.reset", "Reset")}</ButtonPrimary>
          </form>
          {/* ERROR */}
          {apiError &&
            <Alert type="error">
              {apiError}
            </Alert>
          }
        </div>
      </div>
    </div>
  );
};

export default FinishRegistrationPage;