import { User, UserCredentails } from "models/users";
import { getProfile, login, updateUserInfo } from "services/user-service";

import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';

type AuthContext = {
    authToken?: string | null;
    currentUser?: User | null;
    handleLogin: (login: UserCredentails) => Promise<User>;
    handleUpdateProfile: (user: User) => Promise<User>;
    handleLogout: () => Promise<void>;
};

const AuthContext = createContext<AuthContext | undefined>(undefined);

type AuthProviderProps = PropsWithChildren;

export default function AuthProvider({ children }: AuthProviderProps) {
    const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('token'));
    const [currentUser, setCurrentUser] = useState<User | null>();


    function setToken(token: string | null) {
        if (token === null) {
            localStorage.removeItem('token');
            setAuthToken(null);
            setCurrentUser(null);
            return;
        }
        localStorage.setItem('token', token);
        setAuthToken(authToken);
    }

    useEffect(() => {
        async function fetchUser() {
            try {
                const t = localStorage.getItem('token');
                if (!t) {
                    setToken(null);
                    return;
                }
                const response = await getProfile();

                setCurrentUser(response.data.payload);
            } catch {
                setCurrentUser(null);
            }
        }

        fetchUser();
    }, []);

    async function handleLogin(credentials: UserCredentails): Promise<User> {
        const response = await login(credentials);
        const token = response.data.payload.token;
        setToken(token);
        const user = await getProfile();
        setCurrentUser(user.data.payload);
        return user.data.payload;
    }

    async function handleUpdateProfile(user: User): Promise<User> {
        const updatedUser = await updateUserInfo(user);
        setCurrentUser(updatedUser.data.payload);
        return updatedUser.data.payload;
    }

    async function handleLogout() {
        setToken(null);
    }

    return (
        <AuthContext.Provider
            value={{
                authToken,
                currentUser,
                handleLogin,
                handleUpdateProfile,
                handleLogout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be used inside of a AuthProvider');
    }

    return context;
}


