

import LoadingPage from "containers/Loading/LoadingPage";
import { useGeneralStats } from "hooks/stats/useStats";
import { ApprovalStatus } from "models/commons";
import { ListingStatus } from "models/listings";
import { ApplicationGeneralStats, ListingGeneralStats, UserGeneralStats } from "models/stats";
import { UserRole, UserStatus } from "models/users";
import { useTranslation } from "react-i18next";
import { getApprovalStatus } from "utils/getApprovalStatus";
import { getListingStatus } from "utils/getListingStatus";
import { getUserRole } from "utils/getUserRole";
import { getUserStatus } from "utils/getUserStatus";
import CounterStatGrid from "./CounterStatGrid";

const GeneralStats = () => {
    const { t } = useTranslation();

    const { data, error, isLoading } = useGeneralStats();

    const renderUsers = (stats: UserGeneralStats) => {
        return (
            <div className="space-y-6 sm:space-y-8 mb-8">
                <CounterStatGrid title={t("page.stats.user.title", "Users")} stats={[
                    { label: t("page.stats.total", "Total"), count: stats.total || 0, },
                    { label: getUserStatus(t, UserStatus.ACTIVE), count: stats.active || 0 },
                    { label: getUserRole(t, UserRole.LANDLORD) || '', count: stats.landlords || 0 },
                    { label: getUserRole(t, UserRole.STUDENT) || '', count: stats.students || 0 }]} />
            </div>
        );
    };

    const renderListings = (stats: ListingGeneralStats) => {
        return (
            <div className="space-y-6 sm:space-y-8 mb-8">
                <CounterStatGrid title={t("page.stats.listing.title", "Listings")} stats={[
                    { label: t("page.stats.total", "Total"), count: stats.total || 0 },
                    { label: getListingStatus(t, ListingStatus.AVAILABLE), count: stats.available || 0 },
                    { label: getListingStatus(t, ListingStatus.RENTED), count: stats.rented || 0 },
                    { label: getListingStatus(t, ListingStatus.ARCHIVED), count: stats.archived || 0 },
                    { label: getListingStatus(t, ListingStatus.DRAFT), count: stats.draft || 0 },
                    { label: t("listing.views", "Views"), count: stats.views || 0 },
                ]} />
            </div>
        );
    };

    const renderApplications = (stats: ApplicationGeneralStats) => {
        return (
            <div className="space-y-6 sm:space-y-8 mb-8">
                <CounterStatGrid title={t("page.stats.application.title", "Applications")} stats={[
                    { label: t("page.stats.total", "Total"), count: stats.total || 0 },
                    { label: getApprovalStatus(t, ApprovalStatus.APPROVED), count: stats.approved || 0 },
                    { label: getApprovalStatus(t, ApprovalStatus.PENDING), count: stats.pending || 0 },
                    { label: getApprovalStatus(t, ApprovalStatus.REJECTED), count: stats.rejected || 0 }]} />
            </div>
        );
    };

    if (isLoading) {
        return <LoadingPage />;
    }

    return (
        <div>
            {error && <div>Error: {error}</div>}
            {data && renderUsers(data.payload.user)}
            {data && renderListings(data.payload.listing)}
            {data && renderApplications(data.payload.application)}
        </div>
    );
};

export default GeneralStats;
