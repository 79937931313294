import { UserCredentails, UserRole } from "models/users";
import { useAuth } from "providers/AuthProvider";
import { FC, FormEvent, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({});
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>(null);
  const [userLogin, setUserLogin] = useState({} as UserCredentails);
  const { handleLogin } = useAuth();

  function validateForm(): boolean {
    const newErrors: { email?: string; password?: string; } = {};
    if (!userLogin.email) {
      newErrors.email = t("common.validation.email", "Email is required");
    }
    if (!userLogin.password) {
      newErrors.password = t("common.validation.password.required", "Password is required");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const getRedirectPageAfterLogin = (role: UserRole): string => {
    switch (role) {
      case UserRole.ADMIN:
        return '/registration-requests';
      case UserRole.STUDENT:
        return '/my-applications';
      case UserRole.LANDLORD:
        return '/my-listings';
      default:
        return '/account';
    };
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (validateForm()) {
      setApiError(null);
      // Call the login function from the user-service.ts file
      handleLogin(userLogin).then((user) => {

        navigate(getRedirectPageAfterLogin(user.role));
      }).catch((err) => {
        setApiError(t('errors.validation.login.invalid.credentials', 'Invalid credentials'));
      });
    }
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.type === 'file') {
      setUserLogin({
        ...userLogin,
        [e.target.name]: e.target.files![0]
      });
      return;
    }
    setUserLogin({
      ...userLogin,
      [e.target.name]: e.target.value
    });
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Rentals</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("page.login.title")}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('common.field.email', 'Email address')}
              </span>
              <Input
                type="email"
                name="email"
                value={userLogin.email}
                onChange={handleChange}
                className="mt-1"
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('common.field.password', 'Password')}
                <Link to="/forgot-password" className="text-sm">
                  {t('common.button.forgot.password', 'Forgot Password?')}
                </Link>
              </span>
              <Input type="password" className="mt-1" name="password"
                value={userLogin.password}
                onChange={handleChange} />
              {errors.password && <p className="text-red-500">{errors.password}</p>}
            </label>
            <ButtonPrimary type="submit">{t('common.button.continue', 'Continue')}</ButtonPrimary>
          </form>

          {/* ERROR */}
          {apiError &&
            <Alert type="error">
              {apiError}
            </Alert>
          }

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('page.login.new.user', 'New user?')} {' '}
            <Link to="/signup-student">{t('page.login.create.account', 'Create an account')}</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
