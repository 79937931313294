import { FC } from "react";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";

import CommonListingCard from "components/ListingCard/CommonListingCard";
import { Application } from "models/applications";
import { ApprovalStatus } from "models/commons";
import { useTranslation } from "react-i18next";
import { ConfirmationModal } from "shared/Modal/ConfirmationModal";
import { getApprovalStatus } from "utils/getApprovalStatus";
import { getColorForStatus } from "utils/getColorForApprovalStatus";
import ApplicationLetterPopup from "../ApplicationLetter/ApplicationLetterPopup";

export interface MyApplicationCardProps {
  className?: string;
  application: Application;
  handleApplicationDeleted: () => void;
  href: string;
}


const MyApplicationCard: FC<MyApplicationCardProps> = ({
  className = "",
  application,
  handleApplicationDeleted,
  href
}) => {
  const { t } = useTranslation();

  const renderActionButtons = () => {
    return (
      <>
        <ApplicationLetterPopup applicationLetter={application.applicationLetter} />
        {application.status === ApprovalStatus.PENDING && application.administratorApprovalStatus !== ApprovalStatus.REJECTED && (
          <ConfirmationModal confirmText={t(`application.actions.delete.delete`, "Delete")} cancelText={t(`application.actions.delete.cancel`, "Cancel")} title={t(`application.actions.delete.title`, "Delete Application")}
            message={t(`application.actions.delete.description`, "Are you sure you want to delete your application for this listing?")}
            onConfirm={handleApplicationDeleted} onCancel={() => { }}
            renderTrigger={(openModal: Function) => {
              return (
                <>
                  <ButtonSecondary className="hover:bg-red-300 hover:dark:bg-red-800" onClick={() => openModal()}>
                    <i className="las la-trash"></i>
                    <span className="pl-2">{t(`application.actions.delete.button`, "Delete")}</span>
                  </ButtonSecondary>
                </>
              )
            }
            } />
        )}
      </>
    );
  };

  return (
    <CommonListingCard className={className} data={application.listing} href={href} badges={
      <>
        {application.administratorApprovalStatus && application.administratorApprovalStatus !== ApprovalStatus.APPROVED && (
          <Badge
            name={
              <div className="flex items-center">
                <i className="text-sm las la-share-alt"></i>
                <span className="ml-1">{getApprovalStatus(t, application.administratorApprovalStatus)}</span>
              </div>
            }
            color={getColorForStatus(application.administratorApprovalStatus)}
          />
        )}
        {application.administratorApprovalStatus && application.administratorApprovalStatus === ApprovalStatus.APPROVED && application.status && (
          <Badge
            name={
              <div className="flex items-center">
                <i className="text-sm las la-share-alt"></i>
                <span className="ml-1">{getApprovalStatus(t, application.status)}</span>
              </div>
            }
            color={getColorForStatus(application.status)}
          />
        )}
      </>
    }>
      {renderActionButtons()}
    </CommonListingCard >
  );
};

export default MyApplicationCard;
