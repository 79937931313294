import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CommonLayoutProps {
  index: string;
  maxIndex: string;
  onNextPage?: () => void;
  onBackPage?: () => void;
  nextBtnText?: string;
  backBtnText?: string;
  children: React.ReactNode;
  disableNavigation?: boolean;
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index = "",
  maxIndex = "",
  children,
  onBackPage,
  nextBtnText,
  backBtnText,
  onNextPage,
  disableNavigation
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-6xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / {maxIndex}
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          {onBackPage && (
            <ButtonSecondary disabled={disableNavigation} onClick={onBackPage}>{backBtnText || t("page.manageListings.common.button.back", "Go Back")}</ButtonSecondary>
          )}
          {onNextPage && (
            <ButtonPrimary disabled={disableNavigation} onClick={onNextPage}>
              {nextBtnText || t("page.manageListings.common.button.next", "Continue")}
            </ButtonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
