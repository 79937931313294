import { TwMainColor } from "data/types";
import { ListingStatus } from "models/listings";

function getColorForListingStatus(status?: ListingStatus): TwMainColor {
    if (!status) return 'gray';
    switch (status) {
        case 'ARCHIVED':
            return 'gray';
        case 'RENTED':
            return 'red';
        case 'AVAILABLE':
            return 'green';
        case 'DRAFT':
            return 'yellow';
        default:
            return 'gray';
    }
}

export default getColorForListingStatus;