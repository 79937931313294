import { AxiosResponse } from "axios";
import { Application, ApplicationRequest } from "models/applications";
import { ApprovalStatus } from "models/commons";
import { PagingAndSortingFilter } from "models/filters";
import { MessageResponse, PaginatedRespoonseList, ResponseObject } from "models/responses";
import apiClient from "services/api-client";



export function getApplications(filter: PagingAndSortingFilter): Promise<AxiosResponse<PaginatedRespoonseList<Application>>> {
    return apiClient.get<PaginatedRespoonseList<Application>>("/api/v1/applications", { params: filter });
}

export function createApplication(externalId: string, request: ApplicationRequest): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.post<ResponseObject<MessageResponse>>(`/api/v1/applications/listing/${externalId}`, request);
}


export function deleteApplication(externalId: string): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.delete<ResponseObject<MessageResponse>>(`/api/v1/applications/${externalId}`);
}

export function reviewApplicationByAdmin(externalId: string, status: ApprovalStatus): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.put<ResponseObject<MessageResponse>>(`/api/v1/applications/${externalId}/admin-review`, null, { params: { status: status } });
}

export function reviewApplicationByLandlord(externalId: string, status: ApprovalStatus): Promise<AxiosResponse<ResponseObject<MessageResponse>>> {
    return apiClient.put<ResponseObject<MessageResponse>>(`/api/v1/applications/${externalId}/review`, null, { params: { status: status } });
}