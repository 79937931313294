import React from 'react';

export interface CounterStat{
    label: string;
    count: number;
}

interface CounterStatGridProps {
    title: string;
    stats: CounterStat[];
}

const CounterStatGrid: React.FC<CounterStatGridProps> = ({ title, stats }) => {
    return (
        <div className="space-y-6 sm:space-y-8">
            <div className="group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl p-10">
                <h5>{title}</h5>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 pb-2"></div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 justify-between">
                    {stats.map((stat, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <p className="text-3xl font-semibold">{stat.count}</p>
                            <p className="text-neutral-500 dark:text-neutral-400">{stat.label}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CounterStatGrid;