import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { PropertyDescription, PropertyDescriptionValidationError } from "./PageManageListing";

export interface ManageListingDescriptionProps {
  formData: PropertyDescription;
  onNextPage?: (data: PropertyDescription) => void;
  onBackPage?: (data: PropertyDescription) => void;
  index: string;
  maxIndex: string;
  validationErrors: PropertyDescriptionValidationError;
}

const ManageListingDescription: FC<ManageListingDescriptionProps> = ({ onBackPage, onNextPage, formData, index, maxIndex, validationErrors }) => {
  const { t } = useTranslation();
  const [internalFormData, setInternalFormData] = useState<PropertyDescription>(formData);

  useEffect(() => {
    setInternalFormData(formData);
  }, [formData]);

  const handleOnNextPage = () => {
    if (onNextPage)
      onNextPage(internalFormData);
  }

  const handleOnBackPage = () => {
    if (onBackPage)
      onBackPage(internalFormData);
  }

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setInternalFormData({
      ...internalFormData,
      [e.target.name]: e.target.value
    });
  }



  return (
    <CommonLayout
      index={index}
      maxIndex={maxIndex}
      onNextPage={handleOnNextPage}
      onBackPage={handleOnBackPage}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">
            {t("page.manageListings.description.title", "Listing Description")}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("page.manageListings.description.description", "Describe your listing in detail")}
          </span>
        </div>
        <div className="space-y-8">
          <FormItem label={t("page.manageListings.description.field.property.label", "Property")}>
            <Textarea value={internalFormData.description} name="description" onChange={handleChange} rows={14} />
            {validationErrors.description && <span className="text-red-500 dark:text-red-400">{validationErrors.description}</span>}
          </FormItem>

          <FormItem label={t("page.manageListings.description.field.tenant.label", "Tenants")}>
            <Textarea value={internalFormData.tenantDescription} name="tenantDescription" onChange={handleChange} rows={14} />
            {validationErrors.tenantDescription && <span className="text-red-500 dark:text-red-400">{validationErrors.tenantDescription}</span>}
          </FormItem>

          <FormItem label={t("page.manageListings.description.field.location.label", "Location")}>
            <Textarea value={internalFormData.locationDescription} name="locationDescription" onChange={handleChange} rows={14} />
            {validationErrors.locationDescription && <span className="text-red-500 dark:text-red-400">{validationErrors.locationDescription}</span>}
          </FormItem>
        </div>

      </>
    </CommonLayout>
  );
};

export default ManageListingDescription;
