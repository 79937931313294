

import { approvalStatuses } from "data/constants";
import { useStudentRegistrations } from "hooks/users/useUser";
import { ApprovalStatus } from "models/commons";
import { PagingAndSortingFilter, SortDirection } from "models/filters";
import { RegistrationRequestFilter, StudentRegistrationRequest } from "models/users";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { downloadDocument, reviewStundetRegistration } from "services/user-service";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import FilterBar, { Filter } from "shared/Filter/FilterBar";
import PillMultiSelectFilter, { FilterOption } from "shared/Filter/PillMultiSelectFilter";
import { ConfirmationModal } from "shared/Modal/ConfirmationModal";
import Pagination from "shared/Pagination/Pagination";
import { getBadgeColorForAproovalStatus } from "utils/badeColors";
import { formatDateTime } from "utils/formatDate";
import { getApprovalStatus } from "utils/getApprovalStatus";
import { getBasicFilterFromSearchParams } from "utils/queryParamFilterUtils";
import UserInformationPopup from "../UserInformationPopup";

const StudentRegistrationRequests = () => {
    const { t } = useTranslation();
    const [changeTrigger, setChangeTrigger] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<PagingAndSortingFilter>({} as PagingAndSortingFilter);
    const [currentPage, setCurrentPage] = useState(0);
    const { data, error, isLoading } = useStudentRegistrations(filter, [filter, changeTrigger]);
    const [keywordAndSortingFilter, setKeywordAndSortingFilter] = useState<Filter>({} as Filter);
    const [selectedOptions, setSelectedOptions] = useState<FilterOption<ApprovalStatus>[]>([]);

    const statusOptions = approvalStatuses.map((status) => {
        return { label: getApprovalStatus(t, status), value: status } as FilterOption<ApprovalStatus>;
    });

    const sortOptions = [
        { label: t("user.firstName", "First Name"), value: "student.firstName" },
        { label: t("user.lastName", "Last Name"), value: "student.lastName" },
        { label: t("user.email", "Email"), value: "student.email" },
        { label: t("common.dateCreated", "Date Created"), value: "dateCreated" },
        { label: t("common.lastModified", "Last Modified"), value: "lastModified" }
    ];

    function triggerChange() {
        setChangeTrigger(changeTrigger + 1);
    }

    useEffect(() => {
        if (data) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [data]);

    useEffect(() => {
        const newFilter = getBasicFilterFromSearchParams(searchParams, "lastModified") as RegistrationRequestFilter;
        newFilter.statuses = searchParams.has("status") ? searchParams.getAll("status") as ApprovalStatus[] : [ApprovalStatus.PENDING];
        setFilter(newFilter);
        if (newFilter.page) {
            setCurrentPage(newFilter.page);
        }
        setKeywordAndSortingFilter({
            keyword: newFilter.keyword || "",
            sortField: newFilter.sortField,
            direction: newFilter.direction
        });
        setSelectedOptions(
            newFilter.statuses ? statusOptions.filter((option) => newFilter.statuses?.includes(option.value)) : []
        );
    }, [searchParams]);


    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleRegistrationReview = (item: StudentRegistrationRequest, approved: boolean) => {
        reviewStundetRegistration(item.externalId, approved).then(() => {
            triggerChange();
        }).catch(() => {
            console.log("Error");
        });
    }

    const handleStatusChanged = (selectedOptions: FilterOption<ApprovalStatus>[]) => {
        const newFilter = { ...filter, statuses: selectedOptions.map((option) => option.value) };
        setFilter(newFilter);
        searchParams.delete("status");
        if (selectedOptions.length > 0) {
            selectedOptions.forEach(option => searchParams.append("status", option.value));
        }
        else {
            searchParams.set("status", "");
        }
        setSearchParams(searchParams);
    }

    const handleKeywordChange = (keyword: string) => {
        const newFilter = { ...filter, keyword: keyword };
        setFilter(newFilter);
        searchParams.set("keyword", keyword);
        setSearchParams(searchParams);
    }

    const handleSortChange = (sortField: string) => {
        const newFilter = { ...filter, sort: sortField };
        setFilter(newFilter);
        searchParams.set("sortField", sortField);
        setSearchParams(searchParams);
    }

    const handleDirectionChange = (direction: SortDirection) => {
        const newFilter = { ...filter, direction: direction };
        setFilter(newFilter);
        searchParams.set("sortDirection", direction);
        setSearchParams(searchParams);
    }


    const renderSection1 = () => {
        return (
            <div className="space-y-6 sm:space-y-8">
                <div className="group relative  bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl p-10">
                    <div className="space-y-8 pb-8">
                        <FilterBar
                            filter={keywordAndSortingFilter}
                            sortOptions={sortOptions}
                            onDirectionChange={handleDirectionChange}
                            onKeywordChange={handleKeywordChange}
                            onSortChange={handleSortChange}
                        />
                        <div className="flex space-x-4">
                            <PillMultiSelectFilter
                                label={t("user.status", "Status")}
                                defaultSelection={selectedOptions}
                                options={statusOptions}
                                onSelectionChange={handleStatusChanged} />
                        </div>
                    </div>
                    <div className="relative overflow-x-auto sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-3xl">
                                <tr>
                                    <th scope="col" className="px-6 py-3" >{t("user.user", "User")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.registrationRequests.table.status", "Status")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("registrationRequest.proofOfStudy", "Proof of Study")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("common.lastModified", "Last Modified")}</th>
                                    <th scope="col" className="px-6 py-3" ></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data?.payload.map((item) => (
                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                        <td className="px-6 py-4"><UserInformationPopup user={item.student} /></td>
                                        <td className="px-6 py-4">
                                            <Badge name={getApprovalStatus(t, item.status)} color={getBadgeColorForAproovalStatus(item.status)} />
                                        </td>
                                        <td className="px-6 py-4">
                                            <ButtonSecondary onClick={() => downloadDocument(item.proofOfStudyDocument)}>{t("common.button.download", "Download")}</ButtonSecondary>
                                        </td>
                                        <td className="px-6 py-4">{formatDateTime(item.lastModified)}</td>
                                        <td className="px-6 py-4">
                                            {item.status === "PENDING" && (
                                                <ConfirmationModal confirmText={t("registrationRequest.review.approve", "Approve")} cancelText={t("registrationRequest.review.reject", "Reject")} title={t("registrationRequest.review.title", "Review Registration Request")}
                                                    message={t("registrationRequest.review.description", "Please confirm your decision on this registration request")}
                                                    onConfirm={() => handleRegistrationReview(item, true)} onCancel={() => handleRegistrationReview(item, false)}
                                                    renderTrigger={(openModal: Function) => (
                                                        <ButtonPrimary onClick={() => openModal()}>{t("registrationRequest.review.review", "Review")}</ButtonPrimary>
                                                    )} />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {data && data.totalPages > 1 && (
                        <div className="flex mt-16 justify-center items-center">
                            <Pagination
                                totalPages={data.totalPages}
                                currentPage={currentPage}
                                setCurrentPage={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderSection1()}
        </div>
    );
};

export default StudentRegistrationRequests;


