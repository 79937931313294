import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { ChangeEvent, FC, useEffect, useState } from "react";

export interface SearchKeywordInputProps {
  searchKeyword?: string;
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onValueChange?: (value: string) => void;
}

const SearchKeywordInput: FC<SearchKeywordInputProps> = ({
  searchKeyword,
  placeHolder,
  desc,
  className = "nc-flex-1.5",
  onValueChange
}) => {

  const [value, setValue] = useState(searchKeyword);

  useEffect(() => {
    setValue(searchKeyword);
  }, [searchKeyword]);

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  }

  return (
    <div className={`relative flex ${className}`}>
      <div
        className="flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left">
        <div className="text-neutral-300 dark:text-neutral-400">
          <MagnifyingGlassIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            onChange={handleValueChange}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SearchKeywordInput;
