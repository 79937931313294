import Label from "components/Label/Label";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { changePassword } from "services/user-service";
import { Alert } from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { getException } from "utils/getException";
import CommonLayout from "./CommonLayout";

interface FormData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
const AccountPass = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [errors, setErrors] = useState<FormData>({} as FormData);
  const [apiError, setApiError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const validateForm = (): boolean => {
    const newErrors = {} as FormData;
    let isValid = true;
    if (!formData.currentPassword) {
      newErrors.currentPassword = t("common.validation.required", "This field is required");
      isValid = false;
    }
    if (!formData.newPassword) {
      newErrors.newPassword = t("common.validation.required", "This field is required");
      isValid = false;
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = t("common.validation.required", "This field is required");
      isValid = false;
    }
    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = t("common.validation.password.match", "Passwords do not match");
      isValid = false;
    }
    setErrors(newErrors);
    return isValid
  }

  const handleChangePassword = () => {
    setApiError(null);
    setSuccess(null);
    if (validateForm()) {
      changePassword(formData.currentPassword, formData.newPassword).then(() => {
        setFormData({} as FormData);
        setSuccess(t("page.changePassword.success", "Password changed successfully"));
      }).catch((error) => {
        setApiError(getException(t, error.response.data.error.message));
      });
    }
  }

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">{t("page.changePassword.title", "Change Password")}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>{t("page.changePassword.field.current", "Current Password")}</Label>
              <Input name="currentPassword" onChange={handleChange} type="password" className="mt-1.5" />
              {errors.currentPassword && <p className="text-sm text-red-500 mt-1">{errors.currentPassword}</p>}
            </div>
            <div>
              <Label>{t("page.changePassword.field.new", "New Password")}</Label>
              <Input name="newPassword" onChange={handleChange} type="password" className="mt-1.5" />
              {errors.newPassword && <p className="text-sm text-red-500 mt-1">{errors.newPassword}</p>}
            </div>
            <div>
              <Label>{t("page.changePassword.field.confirm", "Confirm Password")}</Label>
              <Input name="confirmPassword" onChange={handleChange} type="password" className="mt-1.5" />
              {errors.confirmPassword && <p className="text-sm text-red-500 mt-1">{errors.confirmPassword}</p>}
            </div>
            <div className="pt-2">
              <ButtonPrimary onClick={handleChangePassword}>{t("page.changePassword.button.update", "Update Password")}</ButtonPrimary>
            </div>
            {apiError && (
              <div className="w-full">
                <Alert type="error" >
                  {apiError}
                </Alert>
              </div>
            )}
            {success && (
              <div className="w-full">
                <Alert type="success" >
                  {success}
                </Alert>
              </div>
            )}
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;

