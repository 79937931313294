import { AxiosResponse } from "axios";
import { AdminNotification, LandlordNotification } from "models/notifications";
import { ResponseObject } from "models/responses";
import apiClient from "./api-client";


export function getLandlordNotifications(): Promise<AxiosResponse<ResponseObject<LandlordNotification>>> {
    return apiClient.get<ResponseObject<LandlordNotification>>(`/api/v1/notifications/landlord`);
}

export function getAdminNotifications(): Promise<AxiosResponse<ResponseObject<AdminNotification>>> {
    return apiClient.get<ResponseObject<AdminNotification>>(`/api/v1/notifications/admin`);
}