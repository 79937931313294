import { getNavigation } from "data/navigation";
import { useAuth } from "providers/AuthProvider";
import { useEffect, useState } from "react";
import { getAdminNotifications, getLandlordNotifications } from "services/notification-service";
import NavigationItem, { NavItemType } from "./NavigationItem";

function Navigation() {
  const { currentUser } = useAuth();
  const [menuItems, setMenuItems] = useState<NavItemType[]>([]);

  useEffect(() => {
    //Implementing the setInterval method
    if (currentUser?.role === "ADMIN" || currentUser?.role === "LANDLORD") {
      checkNotifications();
      const interval = setInterval(() => {
        checkNotifications();
      }, 10000);

      //Clearing the interval
      return () => clearInterval(interval);
    }
  }, [currentUser]);

  useEffect(() => {
    setMenuItems(getNavigation(currentUser?.role));
  }, [currentUser]);

  const checkNotifications = () => {
    if (menuItems?.length > 0) {
      if (currentUser?.role === "ADMIN") {
        getAdminNotifications().then((response) => {
          updateNotificationCount("nav.registrationRequests", response.data.payload.pendingLandlordRegistrationRequests + response.data.payload.pendingStudentRegistrationRequests);
          updateNotificationCount("nav.studentApplications", response.data.payload.pendingApplications);
        }).catch((error) => { });
      } else if (currentUser?.role === "LANDLORD") {
        getLandlordNotifications().then((response) => {
          updateNotificationCount("nav.applications", response.data.payload.pendingApplications);
        }).catch((error) => { });
      }
    }
  }


  const updateNotificationCount = (localizationKey: string, count: number) => {
    setMenuItems((prev) =>
      prev.map((item) => {
        if (item.localizationKey === localizationKey) {
          return {
            ...item,
            isNew: count > 0,
          };
        }
        return item;
      })
    );
  }

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {menuItems?.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
