import { BaseModel } from "./base-entity";
import { NumericRange, PagingAndSortingFilter } from "./filters";

// Address model
export interface Address {
  street: string;
  city: string;
  region: string;
  postalCode: string;
  district?: string;
  houseNumber: string;
}

// Image model
export interface Image {
  main: boolean;
  url: string;
  location: string;
  externalId: string;
}

// UnitType model
export enum UnitType {
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  ROOM = "ROOM",
}

export enum ListingStatus {
  ARCHIVED = "ARCHIVED",
  DRAFT = "DRAFT",
  AVAILABLE = "AVAILABLE",
  RENTED = "RENTED"
}

// Property model
export interface Listing extends BaseModel {
  title: string;
  address: Address;
  unitType: UnitType;
  basicRent: number;
  ancillaryCost: number;
  securityDeposit: number;
  totalRent: number;
  additionalCosts: AdditonalCost[];
  areaSqm: number;
  totalAreaSqm: number;
  bedrooms: number;
  bathrooms: number;
  kitchens: number;
  views: number;
  description: string;
  locationDescription: string;
  tenantDescription: string;
  status?: ListingStatus;
  amenities: string[];
  images: Image[];
}

export interface AdditonalCost {
  cost: number;
  description: string;
}

// Define the SearchFilter interface that extends PagingAndSortingFilter
export interface SearchFilter extends PagingAndSortingFilter {
  price?: NumericRange;
  area?: NumericRange;
  unitTypes?: UnitType[];
}

export interface ListingFilter extends PagingAndSortingFilter {
  status: ListingStatus[];
}