import { NumericRange, PagingAndSortingFilter, SortDirection } from "models/filters";
import { SearchFilter, UnitType } from "models/listings";


export function populateSearchParamsFromSearchFilter(searchParams: URLSearchParams, filter: SearchFilter): void {
    populateSearchParamsFromBasicFilter(searchParams, filter);

    if (filter.unitTypes && filter.unitTypes.length > 0) {
        searchParams.set("unitTypes", filter.unitTypes.map((unitType) => unitType).join(","));
    } else if (searchParams.has("unitTypes")) {
        searchParams.delete("unitTypes");
    }

    if (filter.price?.min) {
        searchParams.set("priceMin", String(filter.price.min));
    }
    else if (searchParams.has("priceMin")) {
        searchParams.delete("priceMin");
    }

    if (filter.price?.max) {
        searchParams.set("priceMax", String(filter.price.max));
    } else if (searchParams.has("priceMax")) {
        searchParams.delete("priceMax");
    }
    if (filter.area?.min) {
        searchParams.set("areaMin", String(filter.area.min));
    } else if (searchParams.has("areaMin")) {
        searchParams.delete("areaMin");
    }
    if (filter.area?.max) {
        searchParams.set("areaMax", String(filter.area.max));
    } else if (searchParams.has("areaMax")) {
        searchParams.delete("areaMax");
    }
}


export function populateSearchParamsFromBasicFilter(searchParams: URLSearchParams, filter: PagingAndSortingFilter): void {
    if (filter.page) {
        searchParams.set("page", String(filter.page));
    } else if (searchParams.has("page")) {
        searchParams.delete("page");
    }
    if (filter.keyword && filter.keyword.trim() !== "") {
        searchParams.set("keyword", filter.keyword);
    } else if (searchParams.has("keyword")) {
        searchParams.delete("keyword");
    }
}

export function getSearchFilterFromSearchParams(searchParams: URLSearchParams): SearchFilter {

    const params = Object.fromEntries(searchParams.entries());
    const { page, unitTypes, priceMin, priceMax, areaMin, areaMax, keyword } = params;

    return {
        page: page ? Number(page) : 0,
        unitTypes: unitTypes ? unitTypes.split(",").map((unitType: string) => unitType as UnitType) : [],
        price: { min: priceMin ? Number(priceMin) : null, max: priceMax ? Number(priceMax) : null } as NumericRange,
        area: { min: areaMin ? Number(areaMin) : null, max: areaMax ? Number(areaMax) : null } as NumericRange,
        keyword: keyword ? keyword : undefined,
        paginated: true,
        pageSize: 10,
        sortField: "basicRent",
        direction: SortDirection.ASC
    } as SearchFilter;
}

export function getBasicFilterFromSearchParams(searchParams: URLSearchParams, defaultSortField = "id", defaultPageSize = 10, defaultSortDirection = SortDirection.DESC): PagingAndSortingFilter {

    const params = Object.fromEntries(searchParams.entries());
    const { page, keyword, sortField, sortDirection } = params;

    return {
        page: page ? Number(page) : 0,
        keyword: keyword ? keyword : undefined,
        paginated: true,
        pageSize: defaultPageSize,
        sortField: sortField ? sortField : defaultSortField,
        direction: sortDirection ? sortDirection as SortDirection : defaultSortDirection
    } as PagingAndSortingFilter;
}
