

import { UserCircleIcon } from '@heroicons/react/24/outline';
import Label from 'components/Label/Label';
import { User, UserRole } from 'models/users';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/Button/Button';
import Input from 'shared/Input/Input';
import NcModal from 'shared/NcModal/NcModal';
import Textarea from 'shared/Textarea/Textarea';
import { formatDate } from 'utils/formatDate';
import { getGender } from 'utils/getGender';

interface UserInformationTableCellProps {
    user: User;
}

const UserInformationPopup: React.FC<UserInformationTableCellProps> = ({ user }) => {
    const { t } = useTranslation();

    const renderUserDetails = () => {
        return (
            <div>
                <div className="flex-grow mt-10 md:mt-0 space-y-6 mb-10 overflow-y-auto h-auto">
                    <div>
                        <Label>{t("user.gender", "Gender")}</Label>
                        <Input name="firstName" disabled={true} className="mt-1.5" value={getGender(t, user.gender)} />
                    </div>
                    <div>
                        <Label>{t("user.firstName", "First Name")}</Label>
                        <Input name="firstName" disabled={true} className="mt-1.5" value={user?.firstName} />
                    </div>
                    <div>
                        <Label>{t("user.lastName", "Last Name")}</Label>
                        <Input name="lastName" disabled={true} className="mt-1.5" value={user?.lastName} />
                    </div>
                    <div>
                        <Label>{t("user.email", "Email Address")}</Label>
                        <Input name="email" disabled={true} className="mt-1.5" value={user?.email} />
                    </div>
                    <div>
                        <Label>{t("user.dateOfBirth", "Date of Birth")}</Label>
                        <Input name="dateOfBirth" disabled={true} className="mt-1.5" value={formatDate(user?.dateOfBirth)} />
                    </div>
                    <div>
                        <Label>{t("user.phoneNumber", "Phone Number")}</Label>
                        <Input name="phoneNumber" disabled={true} className="mt-1.5" value={user?.phoneNumber} />
                    </div>
                    {user?.role === UserRole.STUDENT && (
                        <div>
                            <Label>{t("user.aboutMe", "About me")}</Label>
                            <Textarea name="aboutMe" disabled={true} className="mt-1.5" rows={10} value={user?.aboutMe || ""} />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="flex flex-row items-center space-y-4 mb-2">
                <NcModal
                    contentExtraClass="max-w-md"
                    contentPaddingClass="px-4"
                    renderTrigger={(openModal) => (
                        <Button onClick={() => {
                            openModal()
                        }}>
                            <span className='flex flex-row space-x-2'>
                                <UserCircleIcon className='w-6 h-6' />
                            </span>
                        </Button>
                    )}
                    renderContent={renderUserDetails}
                    modalTitle={t("user.user", "User")}
                />
                <div className="flex flex-col">
                    <span>
                        {user.firstName}
                    </span>
                    <span>
                        {user.lastName}
                    </span>
                    <span>
                        {user.email}
                    </span>
                </div>



            </div></div>
    );
};

export default UserInformationPopup;