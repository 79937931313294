import { listingStatuses } from "data/constants";
import { useListings } from "hooks/listings/useListing";
import { PagingAndSortingFilter, SortDirection } from "models/filters";
import { ListingFilter, ListingStatus } from "models/listings";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FilterBar, { Filter } from "shared/Filter/FilterBar";
import PillMultiSelectFilter, { FilterOption } from "shared/Filter/PillMultiSelectFilter";
import ListResultsInformation from "shared/ListResultsInformation/ListResultsInformation";
import Pagination from "shared/Pagination/Pagination";
import { getListingStatus } from "utils/getListingStatus";
import { getBasicFilterFromSearchParams } from "utils/queryParamFilterUtils";
import AdministrationListingCard from "../../../components/ListingCard/AdministrationListingCard";

interface ApplicationSelectedFilterOptions {
  status: FilterOption<ListingStatus>[];
}


const MyListingsPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<PagingAndSortingFilter>({} as PagingAndSortingFilter);
  const [currentPage, setCurrentPage] = useState(0);
  const { data, error, isLoading } = useListings(filter, [filter]);
  const [keywordAndSortingFilter, setKeywordAndSortingFilter] = useState<Filter>({} as Filter);
  const [selectedOptions, setSelectedOptions] = useState<ApplicationSelectedFilterOptions>({ status: [] });
  const statusOptions = listingStatuses.map((status) => {
    return { label: getListingStatus(t, status), value: status } as FilterOption<ListingStatus>;
  });

  const sortOptions = [
    { label: t("common.dateCreated", "Date Created"), value: "dateCreated" },
    { label: t("common.lastModified", "Last Modified"), value: "lastModified" },
    { label: t("listing.title", "Title"), value: "listing.title" },
  ];

  useEffect(() => {
    if (data) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [data]);

  useEffect(() => {
    const newFilter = getBasicFilterFromSearchParams(searchParams, "lastModified") as ListingFilter;
    newFilter.status = searchParams.getAll("status") as ListingStatus[];
    setFilter(newFilter);
    if (newFilter.page) {
      setCurrentPage(newFilter.page);
    }
    setKeywordAndSortingFilter({
      keyword: newFilter.keyword || "",
      sortField: newFilter.sortField,
      direction: newFilter.direction
    });
    setSelectedOptions({
      status: newFilter.status ? statusOptions.filter((option) => newFilter.status?.includes(option.value)) : []
    });
  }, [searchParams]);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
  };

  const handleStatusChanged = (selectedOptions: FilterOption<ListingStatus>[]) => {
    const newFilter = { ...filter, status: selectedOptions.map((option) => option.value) };
    setFilter(newFilter);
    searchParams.delete("status");
    if (selectedOptions.length > 0) {
      selectedOptions.forEach(option => searchParams.append("status", option.value));
    }
    else {
      searchParams.set("status", "");
    }
    setSearchParams(searchParams);
  }

  const handleKeywordChange = (keyword: string) => {
    const newFilter = { ...filter, keyword: keyword };
    setFilter(newFilter);
    searchParams.set("keyword", keyword);
    setSearchParams(searchParams);
  }

  const handleSortChange = (sortField: string) => {
    const newFilter = { ...filter, sort: sortField };
    setFilter(newFilter);
    searchParams.set("sortField", sortField);
    setSearchParams(searchParams);
  }

  const handleDirectionChange = (direction: SortDirection) => {
    const newFilter = { ...filter, direction: direction };
    setFilter(newFilter);
    searchParams.set("sortDirection", direction);
    setSearchParams(searchParams);
  }

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <Helmet>
          <title>{t("page.myListings.helmet", "My Listings")}</title>
        </Helmet>
        <div>
          <h2 className="text-3xl font-semibold">{t("page.myListings.title", "My Listings")}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="pt-2">
          <ButtonPrimary href="/add-listing">{t("page.myListings.button.create", "Create Listing")}</ButtonPrimary>
        </div>

        <div className="group relative  bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl p-6">
          <div className="space-y-8">
            <FilterBar
              filter={keywordAndSortingFilter}
              sortOptions={sortOptions}
              onDirectionChange={handleDirectionChange}
              onKeywordChange={handleKeywordChange}
              onSortChange={handleSortChange}
            />
            <div className="flex space-x-4">
              <PillMultiSelectFilter
                label={t("page.applicationReview.status", "Status")}
                defaultSelection={selectedOptions.status}
                options={statusOptions}
                onSelectionChange={handleStatusChanged} />
            </div>
            <ListResultsInformation label={t("page.myListings.results.description", "Number of listings: ")} totalResults={data?.totalSize} isLoading={isLoading} error={error} className="w-full" />
          </div>
        </div>

        <div>
          <div className="grid grid-cols-1 gap-6 md:gap-8">
            {data?.payload.map((item) => (
              <AdministrationListingCard key={item.externalId} data={item} href={`/listing/${item.externalId}`} />
            ))}
          </div>
          {data && data.totalPages > 1 && (
            <div className="flex mt-16 justify-center items-center">
              <Pagination
                totalPages={data.totalPages}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 ">

      <div className="container pt-6 pb-24 lg:pb-32">
        {renderSection1()}
      </div>
    </div>
  );
};

export default MyListingsPage;
