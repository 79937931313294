import { Label } from '@headlessui/react/dist/components/label/label';
import ListingCard from 'components/ListingCard/ListingCard';
import FormItem from 'containers/Listing/ListingManage/FormItem';
import { isEmpty } from 'lodash';
import { ApplicationRequest } from 'models/applications';
import { Listing } from 'models/listings';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createApplication } from 'services/application-service';
import { getById } from 'services/listing-service';
import { Alert } from 'shared/Alert/Alert';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Textarea from 'shared/Textarea/Textarea';
import { getException } from 'utils/getException';

export const ApplicationPage = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [listing, setListing] = useState<Listing | undefined>();
    const [applicationLetter, setApplicationLetter] = useState<string>('');
    const [applicationLetterError, setApplicationLetterError] = useState<string>();
    const navgiate = useNavigate();
    const [apiError, setApiError] = useState<string>();

    useEffect(() => {
        if (id) {
            getById(id).then((response) => {
                setListing(response.data.payload);
            }).catch((error) => {
                console.log(error);

            });
        }
    }, [id]);


    const handleCancel = (): void => {
        navgiate("/listing/" + id);
    }

    const validate = (): boolean => {
        let isValid = true;
        if (isEmpty(applicationLetter)) {
            setApplicationLetterError(t("common.validation.required", "This field is required"));
            isValid = false;
        } else {
            setApplicationLetterError(undefined);
        }
        return isValid;
    }

    const handleOnConfirm = (): void => {
        setApiError(undefined)
        if (validate()) {
            createApplication(id as string, { applicationLetter: applicationLetter } as ApplicationRequest).then(() => {
                navgiate("/application-success");
            }).catch((error) => {
                setApiError(getException(t, error.response.data.error.message));
            });
        }
    }

    return (
        <>
            <div className='py-16 px-16'>
                <h1 className='text-2xl font-bold mb-5'>{t("page.createApplication.title", "Application")}</h1>
                {listing && <ListingCard className='hover:shadow-none' listing={listing} href={''} />}

                {/* confirm or cancel */}

                <div className='mt-8 text-center'>

                    <p className='text-neutral-600 dark:text-neutral-300 mt-2'>{t("page.createApplication.description", "Please confirm you want to appy for to the lsiting above")}</p>

                </div>

                <div>
                    <FormItem label={t("application.applicationLetter", "Application letter")}>
                        <Textarea value={applicationLetter} name="tenantDescription" onChange={(e) => setApplicationLetter(e.target.value)} rows={14} />
                        {applicationLetterError && <span className="text-red-500 dark:text-red-400">{applicationLetterError}</span>}
                    </FormItem>
                </div>

                <div className="flex space-x-4 justify-center mt-4">
                    <ButtonSecondary onClick={handleCancel} >{t("page.createApplication.button.cancel", "Cancel")}</ButtonSecondary>
                    <ButtonPrimary onClick={handleOnConfirm} >{t("page.createApplication.button.confirm", "Confirm")}</ButtonPrimary>
                </div>

                {/* ERROR */}
                {apiError &&
                    <Alert containerClassName='my-4' type="error">
                        {apiError}
                    </Alert>
                }
            </div>
        </>
    );

}

export default ApplicationPage;