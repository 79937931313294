import { Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Checkbox from "shared/Checkbox/Checkbox";


export interface FilterOption<T> {
  label: string,
  value: T
}

export interface Props<T> {
  label: string,
  options: FilterOption<T>[],
  defaultSelection: FilterOption<T>[],
  onSelectionChange: (selectedOptions: FilterOption<T>[]) => void
}

const PillMultiSelectFilter = <T,>({ label, options, defaultSelection, onSelectionChange }: Props<T>) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<FilterOption<T>[]>(defaultSelection);
  useEffect(() => {
    setSelectedOptions(defaultSelection);
  }, [defaultSelection]);

  //
  const handleChangeFilterOption = (checked: boolean, option: FilterOption<T>) => {
    let copy = [...selectedOptions];
    checked
      ? copy = [...copy, option]
      : copy = copy.filter((i) => i.value !== option.value);
    setSelectedOptions(copy);
    onSelectionChange(copy);
  };

  const clearSelection = () => {
    setSelectedOptions([]);
    onSelectionChange([]);
  };

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };


  const renderTabsStopPoints = () => {

    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none 
              ${open ? "!border-primary-500 " : ""}
                ${!!options.length
                  ? "!border-primary-500 bg-primary-50"
                  : ""
                }
                `}
            >
              <span className="text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 text-neutral-700 dark:text-neutral-700">
                {selectedOptions.length > 0 ? selectedOptions.map(i => i.label).join(',') : label}
              </span>
              {!selectedOptions.length ? (
                <i className="las la-angle-down ml-2"></i>
              ) : (
                <span onClick={(e) => {
                  e.stopPropagation();
                  clearSelection()
                }}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-[400] w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="flex flex-col px-5 py-6 space-y-5">
                    {options.map((item) => (
                      <div key={item.label} className="">
                        <Checkbox
                          name={item.label}
                          label={item.label}
                          defaultChecked={selectedOptions.some(selected => selected.value === item.value)}
                          onChange={(checked) =>
                            handleChangeFilterOption(checked, item)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonSecondary
                      onClick={() => {
                        close();
                        clearSelection();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("common.button.reset", "Clear")}
                    </ButtonSecondary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      <div className="flex space-x-4">
        {renderTabsStopPoints()}
      </div>
    </div>
  );
};

export default PillMultiSelectFilter;
