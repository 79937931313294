import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

export function setupAuthIntecptors(http: AxiosInstance) {
    http.interceptors.request.use(
        config => {
            const token = localStorage.getItem('token')
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token
            }
            config.withCredentials = true;
            return config
        },
        error => {
            Promise.reject(error)
        }
    )
    http.interceptors.response.use(
        response => response,
        async (error: AxiosError) => {
            const originalRequest = error.config as AxiosRequestConfig;
            if (!originalRequest.url?.includes("authenticate") && error.response?.status === 403) {

                try {
                    const response = await http.post('/api/v1/refresh');
                    const newAccessToken = response.data.payload.token;

                    // Update tokens in local storage
                    localStorage.setItem('token', newAccessToken);

                    if (originalRequest.headers) {
                        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    }
                    return http(originalRequest);
                } catch (refreshError) {
                    return Promise.reject(refreshError);
                } finally {
                }
            }

            return Promise.reject(error);
        }
    )
}

