import { TwMainColor } from "data/types";
import { UserStatus } from "models/users";

function getColorForUserStatus(status?: UserStatus): TwMainColor {
    if (!status) return 'gray';
    switch (status) {
        case UserStatus.ACTIVE:
            return 'green';
        case UserStatus.INACTIVE:
            return 'red';
        case UserStatus.NEW:
            return 'blue';
        case UserStatus.REGISTRATION_PENDING:
            return 'yellow';
        default:
            return 'gray';
    }
}

export default getColorForUserStatus;