

export function formatDateTime(date: string): string {
    if (!date) {
        return '';
    }
    const d = new Date(date);
    // format to locale date and time from browser
    return d.toLocaleString(navigator.language);

}

export function formatDate(date: string): string {
    if (!date) {
        return '';
    }
    const d = new Date(date);
    // format to locale date from browser
    return d.toLocaleDateString(navigator.language);
}