

import { EnvelopeOpenIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/Button/Button';
import NcModal from 'shared/NcModal/NcModal';

interface ApplicationLetterPopupProps {
    applicationLetter: string;
}

const ApplicationLetterPopup: React.FC<ApplicationLetterPopupProps> = ({ applicationLetter }) => {
    const { t } = useTranslation();

    const renderApplciationLetter = () => {
        return (
            <div>
                <div className="flex-grow mt-10 md:mt-0 space-y-6 mb-10 overflow-y-auto h-auto">
                    <div>
                        {applicationLetter?.split('\n').map((line, index) => (
                            <p className="py-1" key={index}>{line}</p>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="flex flex-row items-center space-y-4 mb-2">
                <NcModal
                    contentExtraClass="max-w-3xl"
                    contentPaddingClass="px-4"
                    renderTrigger={(openModal) => (
                        <Button onClick={() => {
                            openModal()
                        }}>
                            <span className='flex flex-row space-x-2'>
                                <EnvelopeOpenIcon className='w-6 h-6' />
                            </span>
                        </Button>
                    )}
                    renderContent={renderApplciationLetter}
                    modalTitle={t("application.applicationLetter", "Application letter")}
                />
            </div>
        </div>
    );
};

export default ApplicationLetterPopup;